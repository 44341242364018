import React from "react"
import Page from "../components/Page"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import useStoryblok from "../utils/storyblok"

interface IndexPageProps {
  [x: string]: any
}

const IndexPage: React.FC<IndexPageProps> = ({ data, location }) => {
  const story = useStoryblok(data.story, location)
  return (
    <Layout location={location}>
      <Page blok={story.content} />
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "home" }) {
      uuid
      name
      full_slug
      content
    }
  }
`

export default IndexPage
